"use client";
import React, { useEffect, useRef } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import AudioPlayIcon from "./svgs/AudioPlayIcon";
import AudioPauseIcon from "./svgs/AudioPauseIcon";

const AudioPlayer = ({ fileBlob }) => {
  const recorderControls = useVoiceVisualizer();
  const ref = useRef(null);

  const {
    togglePauseResume,
    setPreloadedAudioBlob,
    isPausedRecordedAudio,
    isPreloadedBlob,
  } = recorderControls;

  useEffect(() => {
    if (fileBlob && !isPreloadedBlob) {
      setPreloadedAudioBlob(fileBlob);
    }
    if (isPreloadedBlob) {
      setTimeout(() => {
        ref.current.click();
      }, 1000);
    }
  }, [fileBlob, isPreloadedBlob]);

  return (
    <>
      <div className="flex bg-[#5C59E8] items-center w-full gap-2 h-14 rounded-lg">
        <span onClick={togglePauseResume} ref={ref}>
          {isPausedRecordedAudio ? (
            <span className="w-8 h-8 flex-shrink-0 cursor-pointer">
              <AudioPlayIcon />
            </span>
          ) : (
            <span className="w-8 h-8 flex-shrink-0 cursor-pointer">
              <AudioPauseIcon />
            </span>
          )}
        </span>

        <VoiceVisualizer
          mainContainerClassName="text-to-speech"
          // canvasContainerClassName="!w-full"
          controls={recorderControls}
          isControlPanelShown={false}
          isDefaultUIShown={false}
          barWidth={1}
          // gap={1}
          speed={2}
          height={0}
          mainBarColor={"#9D9BF1"}
          secondaryBarColor={"#ffffff"}
          animateCurrentPick={true}
        />
      </div>
    </>
  );
};

export default AudioPlayer;

import { useState } from "react";
import AudioPlayIcon from "./svgs/AudioPlayIcon";
import { Spinner } from "react-bootstrap";
import AudioPlayer from "./AudioPlayer";
import routes from "../configs/api";
import axios from "axios";
import { toast } from "react-toastify";

export const AudioPlayerComponent = ({message}) =>{

    const [audioUrl, setAudioUrl] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);

    const getOrdinal = (n) => {
        const ordinals = [
          "first",
          "second",
          "third",
          "fourth",
          "fifth",
          "sixth",
          "seventh",
          "eighth",
          "ninth",
          "tenth",
        ];
        return ordinals[n - 1] || `${n}th`;
      };
    
    
      const base64ToBlob = (base64String, mimeType) => {
        if (!base64String) return;
        const base64Data = base64String?.replace(/^data:.+;base64,/, "");
        const byteCharacters = atob(base64Data);
        const byteArrays = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays[i] = byteCharacters.charCodeAt(i);
        }
        return new Blob([byteArrays], { type: mimeType });
      };

    const handleTextToSpeech = async (text) => {
        const result = text?.text
          ?.map((item) => {
            if (item.type === "text") {
              return item.content;
            } else if (item.type === "array") {
              return item.content
                .map(
                  (product, index) =>
                    `The ${getOrdinal(index + 1)} product name is ${
                      product.name
                    } and the price of this product is ${product.price}.`
                )
                .join("\n");
            }
          })
          .join("\n\n");
        try {
          setIsDownloading(true);
          const response = await axios.post(
            routes.TEXT_TO_AUDIO,
            { message: result },
            true
          );
          const audioFile = response.data.audio;
          const filesBlob = base64ToBlob(audioFile, "audio/mp3");
          setAudioUrl(filesBlob);
          setIsDownloading(false);
        } catch (e) {
          toast.error(e?.response?.data?.message || e.message);
        } finally {
          setIsDownloading(false);
        }
      };


    return(
    <div>
    {!audioUrl && (
      <div>
        {!isDownloading && (
          <div
            onClick={() => handleTextToSpeech(message)}
            disabled={isDownloading}
            className="playButton"
          >
            <AudioPlayIcon />
          </div>
        )}
        {isDownloading && (
          <div
            className="playButton"
            style={{ height: "15px" }}
          >
            <Spinner animation="border" variant="light" />
          </div>
        )}
      </div>
    )}
    <div className="audio-player">
      {audioUrl && <AudioPlayer fileBlob={audioUrl}/>}
    </div>
  </div>
    )
}

const API_URL = process.env.REACT_APP_API_URL;
const routes = {
  BASE: API_URL,
  DIFY_CHAT: API_URL + "/api/create-dify-user-chats",
  DIFY_PRODUCT_DESCRIPTION: API_URL + "/api/dify-product-description",
  UPDATE_PRODUCT_DESCRIPTION: API_URL + "/api/update-product-description",
  LOGIN: API_URL + "/api/auth/login",
  USER: API_URL + "/api/auth/users",

  AUDIO_TO_TEXT: API_URL + "/api/SpeechToText",
  TEXT_TO_AUDIO: API_URL + "/api/TextToSpeech",

  //----------------ORDER ROUTES ------------------------
  PLACE_ORDER: API_URL + "/api/order-place",
  PAYMENT_ORDER: API_URL + "/api/order-payment",
};
export default routes;

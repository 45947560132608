import React, { useEffect, useRef, useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { IoSendSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import { IoIosPause } from "react-icons/io";
import { images } from "../utils/image";
import routes from "../configs/api";
import axios from "axios";
import { toast } from "react-toastify";
import MicDetactModal from "./MicDetactModal";
import { Spinner } from "react-bootstrap";
import hark from "hark";
import AudioPlayer from "./AudioPlayer";

const SpeechToText = ({
  setIsAudioLoading,
  setQuery,
  isAudioLoading,
  query,
  disabled,
  setInputShow,
  loading,
  setLoading,
  setIsRecordingStart,
  setIsRecordingStarted,
  setIsClearButtonHide,
}) => {
  const recorderControls = useVoiceVisualizer();
  const [showModal, setShowModal] = useState("");
  const [mediaStream, setMediaStream] = useState(null);
  const abortRecording = useRef();
  const silenceTimeout = useRef(null); // Store timeout reference
  const [audioUrl, setAudioUrl] = useState(null);

  const {
    recordedBlob,
    startRecording,
    stopRecording,
    isRecordingInProgress,
    formattedRecordingTime,
    togglePauseResume,
    clearCanvas,
    isPausedRecording,
    error,
  } = recorderControls;

  const convertToText = async (formData) => {
    try {
      setIsAudioLoading(true);
      const response = await axios.post(routes.AUDIO_TO_TEXT, formData, true);
      if (response) {
        setQuery(
          query ? query + " " + response.data.response : response.data.response
        );
      }
      clearCanvas();
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setIsAudioLoading(false);
      setIsClearButtonHide(false);
      setInputShow(false);
      setLoading(false);
    }
    setIsRecordingStarted(false);
  };

  useEffect(() => {
    if (!recordedBlob) return;
    const file = new File([recordedBlob], "temp.mp3", { type: "audio/mp3" });
    const formData = new FormData();

    formData.append("file", file);
    convertToText(formData);
  }, [recordedBlob, error]);

  const removeTrack = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  const setTextValue = async () => {
    stopRecording();
    removeTrack();
    // await delay(1000);
  };
  const handleStartRecording = async () => {
    if (disabled) return;
    let hasError = false;
    let stream = null;
    try {
      // let navigate = navigator
      setIsClearButtonHide(true);
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      if (
        error.message === "Permission denied" ||
        error.message === "Permission dismissed"
      ) {
        setShowModal(error.message);
        hasError = true;
        setInputShow(false);
        setIsClearButtonHide(false);
      }
    }
    if (!hasError) {
      if (stream) {
        setMediaStream(stream);
      }
      // setTimeout(() => {
      setIsRecordingStarted(true);
      // }, 100);
      setInputShow(true);
      setLoading(true);
      startRecording();
    }
  };

  useEffect(() => {
    setIsRecordingStart(isRecordingInProgress);
    let stream;
    let speechEvents;

    if (isRecordingInProgress) {
      const startRecording = async () => {
        try {
          stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          speechEvents = hark(stream);
          speechEvents.on("speaking", () => {
            clearTimeout(abortRecording.current);
            if (silenceTimeout.current) {
              clearTimeout(silenceTimeout.current);
            }
          });
          speechEvents.on("stopped_speaking", () => {
            abortRecording.current = setTimeout(() => {
              console.log("User stopped speaking");
              setTextValue();
            }, process.env.REACT_APP_SPEAKING_STOP_TIMER || 5000);
          });

          silenceTimeout.current = setTimeout(() => {
            removeTrack();
            clearCanvas();
            setInputShow(false);
            setLoading(false);
            setIsRecordingStarted(false);
            setIsClearButtonHide(false);
          }, process.env.REACT_APP_SPEAKING_STOP_TIMER || 5000);
        } catch (error) {
          console.error("Error accessing microphone: ", error);
        }
      };
      startRecording();
      return () => {
        if (speechEvents) {
          speechEvents.stop(); // Stop listening to speech events
        }
        if (stream) {
          stream.getTracks().forEach((track) => track.stop()); // Stop the microphone stream
        }
        clearTimeout(abortRecording.current); // Clear the timeout for speech end detection
        if (silenceTimeout.current) {
          clearTimeout(silenceTimeout.current); // Clear the silence timeout on cleanup
        }
      };
    }
  }, [isRecordingInProgress]);

  return (
    <div
      className={`d-flex ${
        isAudioLoading ? "justify-content-center" : "justify-content-end"
      }  w-100 helloworld`}
    >
      {/* {audioUrl && (
        <div>
          <h3>Audio Output</h3>
          <audio controls>
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )} */}
      <div className={`${isAudioLoading ? "" : "text-container"}  w-100`}>
        {isRecordingInProgress && (
          <>
            <div
              className="speech-to-text-icon-delete"
              onClick={() => {
                removeTrack();
                clearCanvas();
                setInputShow(false);
                setLoading(false);
                setIsRecordingStarted(false);
                setIsClearButtonHide(false);
              }}
            >
              <MdDelete
                size={27}
                style={{ flexShrink: 0, color: "rgb(150 29 21)" }}
              />
            </div>
            <VoiceVisualizer
              mainContainerClassName="visualizer"
              canvasContainerClassName="canvas"
              controls={recorderControls}
              isControlPanelShown={false}
              isDefaultUIShown={false}
              barWidth={3}
              speed={2}
              height={40}
              gap={0}
              mainBarColor={"#9593F0"}
              secondaryBarColor={"#ffffff"}
              animateCurrentPick={true}
            />
            <div className="recording-time pe-3">
              <span className="time-text">{formattedRecordingTime}</span>
            </div>
          </>
        )}
        {!isRecordingInProgress && (
          <span className="pointer">
            {isAudioLoading ? (
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                  paddingTop: "4px",
                }}
              >
                <Spinner animation="border" size="sm" />

                <span>Converting to text....</span>
              </div>
            ) : (
              <button
                className={`${
                  loading ? "disable-button-color" : "send-button"
                }  ms-1 me-1 me-md-0 ms-md-3`}
                type="button"
                onClick={handleStartRecording}
                disabled={loading}
              >
                <img src={images.mic} alt="microphone" width={"35px"} />
              </button>
            )}
          </span>
        )}
        {isRecordingInProgress && (
          <>
            <div className="speech-to-text-icon" onClick={togglePauseResume}>
              {isPausedRecording ? (
                <FaPlay size={16} />
              ) : (
                <IoIosPause size={70} />
              )}
            </div>
            <div className="speech-to-text-icon" onClick={setTextValue}>
              <img src={images.plain} alt="send" />
            </div>
          </>
        )}
      </div>
      {showModal && (
        <MicDetactModal
          showModal={showModal}
          setShowModal={setShowModal}
          setIsRecordingStart={setIsRecordingStart}
        />
      )}

      {/* {isRecordingInProgress && (
        <div className="flex justify-center gap-3">
          <div
            className="ml-1 sm:ml-2 cursor-pointer block sm:hidden"
            onClick={setTextValue}
          >
            <IoSendSharp className="w-10 h-10" />
          </div>
          <div
            className="ml-1 sm:ml-2 cursor-pointer block sm:hidden"
            onClick={() => {
              removeTrack();
              clearCanvas();
            }}
          >
            <MdDelete className="w-10 h-10" />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SpeechToText;

import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { images } from "../../utils/image";
import AddPlus from "../../component/AddPlus";
import Button from "../../component/Button";
import Cart from "../../component/Cart";
import { Link, useLocation } from "react-router-dom";
import { AuthUserContext } from "../../context";
import difyProductDescription from "../../services/Chat/difyProductDescription";
import { toast } from "react-toastify";
import Response from "../../component/Response";
import InactivityHandler from "../../component/InactivityHandler";
import { FaCheck } from "react-icons/fa";
const ProductDetail = () => {
  const [count, setCount] = useState(1);
  const { setCartCount, cartCount, cartItems, setCartItems } =
    useContext(AuthUserContext);
  const location = useLocation();
  const [productDescription, setProductDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const product = location.state?.product;
  const handleAddToCart = () => {
    const existingProductIndex = cartItems.findIndex(
      (item) => item.id === product.id
    );

    let newCartItems = [...cartItems];
    let newCountInCart = count;

    if (existingProductIndex !== -1) {
      const existingProduct = newCartItems[existingProductIndex];
      const updatedCount = existingProduct.count + count;
      if (updatedCount > product.quantity) {
        newCountInCart = product.quantity - existingProduct.count;
        existingProduct.count = product.quantity;
      } else {
        newCountInCart = count;
        existingProduct.count = updatedCount;
      }
    } else {
      newCountInCart = count > product.quantity ? product.quantity : count;
      newCartItems = [
        ...cartItems,
        {
          ...product,
          count: newCountInCart,
        },
      ];
    }
    setCartItems(newCartItems);
    setCartCount((prevCartCount) => prevCartCount + newCountInCart);
    setCount(1);
    setIsAddedToCart(true);

    setTimeout(() => {
      setIsAddedToCart(false);
    }, 1000);
  };

  const remainingQuantity =
    product.quantity -
    cartItems.reduce(
      (acc, item) => (item.id === product.id ? item.count : acc),
      0
    );
  const getProductDetails = async () => {
    try {
      const result = await difyProductDescription({
        text: product?.name,
        id: product?.id,
      });
      setProductDescription(result?.data?.answer);
      setProductDetails(result?.data);
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      console.log("e", e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
    getProductDetails();
  }, []);
  return (
    <Container fluid>
      <Row className="top-box position-relative header-diplay">
        <Col
          xl={6}
          lg={8}
          md={10}
          xs={11}
          className="p-0 d-flex justify-content-center "
        >
          <Row className=" w-100 p-0 ">
            <Col
              xs={2}
              className="d-flex justify-content-start align-items-end ps-0"
            >
              <Link to="/">
                <img src={images.buttonSquare} alt="logo" />
              </Link>
            </Col>
            <Col md={8} xs={8} className="">
              {/* <img src={images.logo} alt="logo" className="logo-width" /> */}
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-end align-items-end pe-0"
            >
              <Cart />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bottom-box-cart d-flex justify-content-center align-items-start">
        <Col
          xl={6}
          lg={8}
          md={10}
          sm={12}
          className=" card-image-cart-item mb-3 p-4"
        >
          {isLoading ? (
            <div
              className=" d-flex flex-column justify-content-center align-items-center"
              style={{ height: "500px" }}
            >
              <Spinner animation="border" variant="light" />
            </div>
          ) : (
            <>
              <Row className="pt-2 disoplay-cart-icon-mobile">
                <Col
                  xs={2}
                  className="d-flex justify-content-start align-items-end ps-0 "
                >
                  <Link to="/">
                    <img src={images.buttonSquare} alt="logo" />
                  </Link>
                </Col>
                <Col
                  md={8}
                  xs={8}
                  className=" d-flex align-items-center justify-content-center"
                >
                  <h2 className="product-details-h2 text-start pt-2">
                    Product Details
                  </h2>
                </Col>
                <Col
                  xs={2}
                  className="d-flex justify-content-end align-items-end pe-0"
                >
                  <Cart />
                </Col>
              </Row>
              <Row className="ps-3 pe-3 pt-2 cart-item-mobile-display">
                <Col xs={12} className=" ps-0">
                  <h2 className="product-details-h2 text-start">
                    Product Details
                  </h2>
                </Col>
              </Row>

              <Row
                className="ps-3 pe-3 pt-md-3 pt-4"
                style={{ minHeight: "430px" }}
              >
                <Col md={5} xs={12} className=" p-1">
                  <img
                    src={
                      product.image && product.image !== "nan"
                        ? product.image
                        : images.dummy
                    }
                    alt="card"
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  />
                </Col>

                <Col
                  md={7}
                  xs={12}
                  className="text-start pt-md-0 pt-4 ps-md-2 ps-0"
                >
                  <h3 className="organic-h3 pt-1">{product?.name}</h3>
                  <div class="product__Tags" style={{ marginTop: "20px" }}>
                    {productDetails?.strain &&
                      productDetails?.strain?.toLowerCase() != "nan" && (
                        <button role="link" class="infoChipPill">
                          <span class="info-chip">
                            <b>{productDetails?.strain}</b>
                          </span>
                        </button>
                      )}
                    {productDetails?.THCMax &&
                    !isNaN(productDetails?.THCMax) &&
                    productDetails?.THCMin &&
                    !isNaN(productDetails?.THCMin) ? (
                      <button class="infoChipPill">
                        <span class="info-chip">
                          <b>THC: </b>&nbsp;
                          <>
                            {productDetails?.THCMax === productDetails?.THCMin
                              ? `${productDetails?.THCMax}%`
                              : `${productDetails?.THCMin}% - ${productDetails?.THCMax}%`}
                          </>
                        </span>
                      </button>
                    ) : (productDetails?.THCMax &&
                        !isNaN(productDetails?.THCMax)) ||
                      (productDetails?.THCMin &&
                        !isNaN(productDetails?.THCMin)) ? (
                      <button class="infoChipPill">
                        <span class="info-chip">
                          <b>THC: </b>&nbsp;
                          <>
                            {productDetails?.THCMax &&
                            !isNaN(productDetails?.THCMax)
                              ? `${productDetails?.THCMax}%`
                              : `${productDetails?.THCMin}%`}
                          </>
                        </span>
                      </button>
                    ) : (
                      <></>
                    )}

                    {productDetails?.CBDMax &&
                    !isNaN(productDetails?.CBDMax) &&
                    productDetails?.CBDMin &&
                    !isNaN(productDetails?.CBDMin) ? (
                      <button class="infoChipPill">
                        <span class="info-chip">
                          <b>CBD: </b>&nbsp;
                          <>
                            {productDetails?.CBDMax === productDetails?.CBDMin
                              ? `${productDetails?.CBDMax}%`
                              : `${productDetails?.CBDMin}% - ${productDetails?.CBDMax}%`}
                          </>
                        </span>
                      </button>
                    ) : (productDetails?.CBDMax &&
                        !isNaN(productDetails?.CBDMax)) ||
                      (productDetails?.CBDMin &&
                        !isNaN(productDetails?.CBDMin)) ? (
                      <button class="infoChipPill">
                        <span class="info-chip">
                          <b>CBD: </b>&nbsp;
                          <>
                            {productDetails?.CBDMax &&
                            !isNaN(productDetails?.CBDMax)
                              ? `${productDetails?.CBDMax}%`
                              : `${productDetails?.CBDMin}%`}
                          </>
                        </span>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="product-per-pack-span">Quantity : </span>
                      <span className="product-per-pack-span-quantity">
                        &nbsp;{product?.quantity}
                      </span>
                    </div>
                    <div>
                      <span className="product-price">${product?.price}</span>
                      <span className="product-per-pack-span"> Per Pack</span>
                    </div>
                  </div>
                  <hr />
                  {productDescription && (
                    <p>{productDescription}</p>
                    // <Response answer={} />
                  )}
                </Col>
                <Row className="d-flex justify-content-end p-0">
                  <Col md={7} xs={12} className="pe-0">
                    <hr />
                    <Row className="pt-2">
                      <Col xs={4} className=" pe-0 ps-md-3">
                        <AddPlus
                          className={"add-minus-box"}
                          setCount={setCount}
                          count={count}
                          maxQuantity={remainingQuantity}
                        />
                      </Col>
                      <Col xs={8}>
                        <Button
                          disabled={remainingQuantity === 0 || isAddedToCart}
                          className={
                            remainingQuantity === 0 && !isAddedToCart
                              ? "buy-now-btn-detail-disable"
                              : isAddedToCart
                              ? "buy-now-btn-success"
                              : "buy-now-btn-detail"
                          }
                          buttonText={
                            isAddedToCart ? (
                              <div className="d-flex align-items-center">
                                <FaCheck style={{ marginRight: "5px" }} />
                                Added to cart
                              </div>
                            ) : (
                              "Add To Cart"
                            )
                          }
                          onClick={handleAddToCart}
                          tooltip={"tooltip"}
                          title={
                            remainingQuantity === 0 &&
                            "Maximum quantity reached"
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <InactivityHandler />
    </Container>
  );
};

export default ProductDetail;

import React from "react";

const AudioPlayIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#9D9BF1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.2211V20.7789L20.4338 16L13 11.2211ZM11.6667 10.6106C11.6667 9.81939 12.5419 9.34155 13.2074 9.76938L21.591 15.1588C22.2033 15.5525 22.2033 16.4475 21.591 16.8412L13.2074 22.2306C12.5419 22.6585 11.6667 22.1806 11.6667 21.3894V10.6106Z"
        fill="white"
      />
    </svg>
  );
};
export default AudioPlayIcon;

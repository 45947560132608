import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
const Response = ({ answer }) => {
  return (
      <div className="">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({ node, ...props }) => {
              return <p className="product-description mb-0 pt-2 padding-right-28" {...props} />
            },
            h6: ({ node, ...props }) => {
              return <h6 className="pt-2" {...props} />
            },
            ol: ({ node, ...props }) => {
              return <ol className="pt-2" {...props} />
            },
            ul: ({ node, ...props }) => {
              return <ul className="pt-2" {...props} />
            },
            li: ({ node, ...props }) => {
              return <li className="pt-2" {...props} />
            },
            img: ({ node, ...props }) => (
              <img style={{ display: "none" }} {...props} alt="product"/>
            ),
            pre: ({ node, ...props }) => (
              <div>
                <pre className="pre-container" {...props} />
              </div>
            ),
            code: ({ node, inline, className, children, ...props }) => {
              return !inline ? (
                <code className="text-red-500" {...props}>
                  {children}
                </code>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            a: ({ node, ...props }) => {
              const content = props.children;
              return content?.toString().includes("nan") ? null : (
                <a
                  {...props}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff" }}
                >
                  {props.children}
                </a>
              );
            },
          }}
        >
          {answer}
        </ReactMarkdown>
      </div>
  );
};

export default Response;

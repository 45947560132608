import React from "react";

const AudioPauseIcon = () => {
  return (
    <svg
     width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2812 12.0312V20.9688C15.2812 21.292 15.1528 21.602 14.9243 21.8305C14.6957 22.0591 14.3857 22.1875 14.0625 22.1875H13.6562C13.333 22.1875 13.023 22.0591 12.7945 21.8305C12.5659 21.602 12.4375 21.292 12.4375 20.9688V12.0312C12.4375 11.708 12.5659 11.398 12.7945 11.1695C13.023 10.9409 13.333 10.8125 13.6562 10.8125H14.0625C14.3857 10.8125 14.6957 10.9409 14.9243 11.1695C15.1528 11.398 15.2812 11.708 15.2812 12.0312ZM19.3438 10.8125H18.9375C18.6143 10.8125 18.3043 10.9409 18.0757 11.1695C17.8472 11.398 17.7188 11.708 17.7188 12.0312V20.9688C17.7188 21.292 17.8472 21.602 18.0757 21.8305C18.3043 22.0591 18.6143 22.1875 18.9375 22.1875H19.3438C19.667 22.1875 19.977 22.0591 20.2055 21.8305C20.4341 21.602 20.5625 21.292 20.5625 20.9688V12.0312C20.5625 11.708 20.4341 11.398 20.2055 11.1695C19.977 10.9409 19.667 10.8125 19.3438 10.8125Z"
        fill="white"
      />
      <rect width="32" height="32" rx="16" fill="#9D9BF1" />
      <path
        d="M14.8438 11.2188V20.1562C14.8438 20.4795 14.7153 20.7895 14.4868 21.018C14.2582 21.2466 13.9482 21.375 13.625 21.375H13.2188C12.8955 21.375 12.5855 21.2466 12.357 21.018C12.1284 20.7895 12 20.4795 12 20.1562V11.2188C12 10.8955 12.1284 10.5855 12.357 10.357C12.5855 10.1284 12.8955 10 13.2188 10H13.625C13.9482 10 14.2582 10.1284 14.4868 10.357C14.7153 10.5855 14.8438 10.8955 14.8438 11.2188ZM18.9062 10H18.5C18.1768 10 17.8668 10.1284 17.6382 10.357C17.4097 10.5855 17.2812 10.8955 17.2812 11.2188V20.1562C17.2812 20.4795 17.4097 20.7895 17.6382 21.018C17.8668 21.2466 18.1768 21.375 18.5 21.375H18.9062C19.2295 21.375 19.5395 21.2466 19.768 21.018C19.9966 20.7895 20.125 20.4795 20.125 20.1562V11.2188C20.125 10.8955 19.9966 10.5855 19.768 10.357C19.5395 10.1284 19.2295 10 18.9062 10Z"
        fill="white"
      />
    </svg>
  );
};
export default AudioPauseIcon;

import logo from "../assets/logo.svg";
import robot from "../assets/robot.svg";
import microphone from "../assets/microphone.svg";
import send from "../assets/send.svg";
import memoji from "../assets/memoji.svg";
import memojiOne from "../assets/memojiOne.svg";
import drug from "../assets/drug.svg";
import cart from "../assets/Cart.svg";
import buttonSquare from "../assets/Button - Square.svg";
import card from "../assets/card.svg";
import deleteSvg from "../assets/delete.svg";
import empty from "../assets/empty.svg";
import notfound from "../assets/notFound.svg";
import clear from "../assets/clear.svg";
import plain from "../assets/Plain.svg";
import dummy from "../assets/dummy.png";
import uploadImage from "../assets/upload-image.svg";
import mic from "../assets/mic.svg";
import relaxedSVG from "../assets/Highest THC.svg";
import relievedSVG from "../assets/Energy Booster.svg";
import energisedSVG from "../assets/Help with Anxiety.svg";
import cheerfulSVG from "../assets/Help with Sleep.svg";
import exhilaratedSVG from "../assets/Help with Pain.svg";
import creativeSVG from "../assets/Highest CBD.svg";
import countDown from "../assets/count-down.svg";
import alarm from "../assets/alarm.svg"
import verified from "../assets/verified.svg"
export const images = {
  logo,
  robot,
  microphone,
  send,
  memoji,
  memojiOne,
  drug,
  cart,
  buttonSquare,
  card,
  deleteSvg,
  empty,
  notfound,
  plain,
  clear,
  dummy,
  uploadImage,
  mic,
  relaxedSVG,
  relievedSVG,
  energisedSVG,
  cheerfulSVG,
  exhilaratedSVG,
  creativeSVG,
  countDown,
  alarm, 
  verified
};
